// .clndr {
//   .controls {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     margin-bottom: 24px;
//     @include max($lg) {
//       margin-bottom: 20px;
//     }
//     .month {
//       font-size: 36px;
//       font-weight: bold;
//       @include max($lg) {
//         font-size: 30px;
//       }
//       @include max($xxs) {
//         font-size: 24px;
//       }
//     }

//     .icon-w {
//       margin-left: 20px;
//     }
//   }

//   .days-container {
//     position: relative;

//     .days {
//       text-align: center;
//       display: flex;
//       justify-content: center;
//       flex-wrap: wrap;
//       transition: left 0.3s;

//       @include max($xxl) {
//         padding: 0 0 10px;
//       }
//       @include max($lg) {
//         width: 280px;
//         margin: 0 auto;
//       }
//       @include max($xxs) {
//         width: 224px;
//       }

//       .day,
//       .empty {
//         width: 38px;
//         height: 38px;
//         line-height: 34px;
//         font-size: 15px;
//         margin: 1px;
//         border: 2px solid $yellow;
//         font-weight: bold;
//         cursor: default;
//         @include max($xxs) {
//           width: 30px;
//           height: 30px;
//           line-height: 28px;
//           border-width: 1px;
//         }
//         &.event {
//           background: $brown;
//           transition: all 0.2s;
//           cursor: pointer;

//           // &:hover {
//           //   background: #ddd;
//           // }

//           // &:not(.adjacent-month) {
//           //   &.event-type-1 {
//           //     background: #AC7949;
//           //   }
//           // }
//           // a {
//           //   color: #fff;

//           //   &:hover {
//           //   }
//           // }
//         }


//         &.adjacent-month {
//           color: rgba(0, 0, 0, 0.3);
//         }

//         &.active {
//           background: #fff;
//           color: $blue5;
//           border-color: #fff;
//         }

//         &.calendar-dow-5,
//         &.calendar-dow-6 {
//           border-color: $brown;
//         }
//       }

//       .empty {
//         display: none; 
//         border-color: transparent;
//         @include max($lg) {
//           display: block;
//         }
//       }
//     }
//   }
// }

.section-calendar {
  background: $blue5;
  padding: 138px 0 165px;
  margin: -80px 0 40px;
  position: relative;
  color: #fff;
  
  @include max($xxl) {
    margin-top: 0;
    padding: 30px 0 120px;
  }
  
  @include max($lg) {
    margin-top: 0;
    padding: 30px 0 80px;
  }
  
  &:after {
    content: "";
    border-width: 40px 55px 0;
    border-style: solid;
    border-color: $blue5 transparent transparent;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  
    @include max($lg) {
      border-width: 20px 27px 0;
    }
  }
  
  

  .calendar-wrapper {
    position: relative;
    margin-bottom: 35px;
    height: 115px;
    @include max($xxxl) {
      height: auto;
    }
    .container {
      position: relative;
    }
      .calendar-title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;
        @include max($lg) {
          margin-bottom: 20px;
        }
        h1 {
          font-size: 36px;
          font-weight: bold;
          margin: 0;
          @include max($lg) {
            font-size: 30px;
          }
          @include max($xxs) {
            font-size: 24px;
          }
        }
    
        .icon-w {
          margin-left: 20px;
        }
      }
    .calendar-month {
      @include max($xxxl) {
        padding: 0 60px;
      }
      @include max($sm) {
        padding: 0 30px;
      }
      .days {
        text-align: center;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        transition: left 0.3s;
      
        @include max($xxl) {
          padding: 0 0 10px;
        }
      
        @include max($lg) {
          width: 280px;
          margin: 0 auto;
        }
      
        @include max($xxs) {
          width: 224px;
        }
      
        .day,
        .empty {
          width: 38px;
          height: 38px;
          line-height: 34px;
          font-size: 15px;
          margin: 1px;
          border: 2px solid $yellow;
          font-weight: bold;
          cursor: default;
          display: flex;
          align-items: stretch;
          justify-content: center;
          text-align: center;
      
          @include max($xxs) {
            width: 30px;
            height: 30px;
            line-height: 28px;
            border-width: 1px;
          }
      
          &.event {
            background: $brown;
            a {
              display: block;
              width: 100%;
              text-align: center;
              color: #fff;
              &:hover {
                opacity: 1;
                background-color: rgba(#fff, 0.1);
              }
            }
          }
      
      
          &.active {
            background: #fff;
            border-color: #fff;
            a {
              color: $blue5;
              cursor: default;
              pointer-events: none;
            }
          }
      
          &.day-weekend {
            border-color: $brown;
          }
        }
      
        .empty {
          display: none;
          border-color: transparent;
      
          @include max($lg) {
            display: block;
          }
        }
      }
    }

    // .calendar-swiper {
    //   @include max($xxxl) {
    //     padding: 0 60px;
    //   }
    //   @include max($sm) {
    //     padding: 0 40px;
    //   }
    // }
    // .swiper-slide {
    //   transition: all 0.2s;
    //   background: $blue5;
    //   opacity: 0 !important;
    //   visibility: hidden;
    //   // display: none;
    //   &.swiper-slide-visible {
    //     opacity: 1 !important;
    //     visibility: visible;
    //   }
    // }
    
    .month-prev,
    .month-next {
      width: 32px;
      height: 52px;
      position: absolute;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      transition: all 0.2s;
    
      @include max($lg) {
        width: 27px;
        height: 42px;
      }
    
      &:hover {
        opacity: 0.8;
      }
    
      &.swiper-button-disabled {
        opacity: 0.4;
      }
    
      .icon {
        max-width: 100%;
        max-height: 100%;
      }
    }
    
    .month-prev {
      left: 70px;
    
      @include max($huge) {
        left: 20px;
      }
      @include max($sm) {
        left: 10px;
      }
    }
    
    .month-next {
      right: 70px;
    
      @include max($huge) {
        right: 20px;
      }
      @include max($sm) {
        right: 10px;
      }
    }
    
    .link {
      position: absolute;
      z-index: 1;
      top: 4px;
      color: #fff;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: all 0.2s;
      @include max($lg) {
        display: none;
      }
      &:hover {
        opacity: 0.9;
      }
      .icon-w {
        .icon {
          @include max($sm) {
            order: 1;
          }
        }
      }
    
      .text {
        @include max($sm) {
          order: 2;
        }
      }
    
      &.link-left {
        left: 30px;
    
        @include max($lg) {
          order: 2;
          left: 20px;
        }
    
        @include max($sm) {
          margin-bottom: 10px;
          left: 15px;
        }
    
        .icon-w {
          margin-right: 15px;
        }
      }
    
      &.link-right {
        right: 30px;
        justify-content: flex-end;
        text-align: right;
    
        @include max($lg) {
          order: 3;
          right: 20px;
        }
    
        @include max($sm) {
          text-align: left;
          justify-content: flex-start;
          right: 15px;
        }
    
        .icon-w {
          margin-left: 15px;
    
          @include max($sm) {
            margin: 0 15px 0 0;
          }
        }
      }
      &.disabled {
        pointer-events: none;
        opacity: 0.4;
      }
    }
  }

  .calendar-events {
    .calendar-events-text {
      text-align: center;
      margin-bottom: 50px;
    }
    .calendar-event-list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;
      .calendar-event-item {
        width: 33.3333%;
        flex: 0 0 33.3333%;
        padding: 0 10px 50px;
        @include max($lg) {
          width: 50%;
          flex: 0 0 50%;
          padding: 0 10px 30px;
        }
        @include max($sm) {
          width: 100%;
          flex: 0 0 100%;
          padding: 0 10px 20px;
        }
        .event-item {
          padding: 25px 40px;
          min-height: 205px;
          @include max($lg) {
            padding: 20px;
          }
          @include max($sm) {
            min-height: 0;
          }
        }
      }
    }    
  }  
}

.event-item {
  background: $blue;
  position: relative;
  color: #fff;
  line-height: 1.1;
  
  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: -7px;
    height: 7px;
    width: 68%;
    background: linear-gradient(to right, $yellow 0%, $brown 100%);
  }
  .event-date {
    margin-bottom: 2px;
  }
  .event-time {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    @include max($sm) {
      font-size: 20px;
    }
  }
  .event-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 7px;
    @include max($sm) {
      font-size: 24px;
    }
  }
  .event-desc {
  }
}

