/***** Team *****/

.section-team {
  background: $blue5;
  padding: 138px 0 165px;
  margin: -80px 0 40px;
  position: relative;
  color: #fff;
  @include max($xxl) {
    margin-top: 0;
    padding: 30px 0 120px;
  }
  @include max($lg) {
    margin-top: 0;
    padding: 30px 0 80px;
  }
  &:after {
    content: "";
    border-width: 40px 55px 0;
    border-style: solid;
    border-color: $blue5 transparent transparent;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    @include max($lg) {
      border-width: 20px 27px 0;
    }
  }

  .team-img {
    position: relative;
    margin-bottom: 25px;
    line-height: 0;
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: -7px;
      left: 0;
      height: 7px;
      width: 50%;
      background: linear-gradient(to right, $yellow 0%, $brown 100%);
    }
    img {
    }
  }

  .team-top {
    background: #02284f;
    padding: 90px 0 70px;
    margin-top: -90px;
    @include max($lg) {
    }
    .team-h {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      h1 {
        margin: 0 18px 0 0;
      }
      .icon-w {
        color: #fff;
      }
    }
    .team-desc {
      p {
      }
    }

    .rhombs {
      margin: 50px auto;
      @include max($sm) {
        margin: 30px 0;
        width: 100%;
      }
    }
  }

  .team-group {
    &:not(:last-child) {
      margin-bottom: 90px;
      @include max($sm) {
        margin-bottom: 50px;
      }
    }
    .team-group-h {
      margin-bottom: 60px;
      @include max($lg) {
        margin-bottom: 30px;
      }
      h2 {
        margin: 0;
      }
    }
    .team-list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px -75px;
      @include max($xxl) {
        margin: 0 -15px -50px;
      }
      @include max($sm) {
        display: block;
        margin: 0 0 -50px;
      }
      .member {
        padding: 0 27px 75px;
        width: 33.3333%;
        flex: 0 0 33.3333%;
        @include max($xxl) {
          padding: 0 15px 50px;
        }
        @include max($lg) {
          width: 50%;
          flex: 0 0 50%;
        }
        @include max($sm) {
          width: 100%;
          padding: 0 0 50px;
        }
      }
    }
  }

  .team-bottom {
    padding: 65px 0 100px;
    @include max($lg) {
      padding: 40px 0;
    }
  }
}

.member {
  text-align: center;
  .member-img-w {
    position: relative;
    margin-bottom: 36px;
    line-height: 0;
  }
  .member-img {
    position: relative;
    padding: 8px;
    &:before {
      content: "";
      display: block;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url(../images/el/photo-border.png) 50% 50% no-repeat;
      background-size: contain;
    }
    img {
      position: relative;
      z-index: 0;
      border-radius: 50%;
    }
  }
  .member-number {
    position: absolute;
    bottom: -28px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    background: url(../images/icons/rhomb-border.svg) 50% 50% no-repeat;
    background-size: contain;
    width: 75px;
    height: 75px;
    text-align: center;
    color: #fff;
    font-size: 30px;
    font-weight: bold;
    padding: 10px 5px;
    line-height: 55px;
  }
  .member-body {
  }
  .member-name {
    font-weight: bold;
    font-size: 24px;
    line-height: 1.2;
    @include max($lg) {
      font-size: 20px;
    }
    @include max($sm) {
      font-size: 18px;
    }
  }
  .member-position {
    color: $brown;
    font-weight: bold;
    font-size: 24px;
    line-height: 1.2;
    margin-bottom: 16px;
    @include max($lg) {
      font-size: 20px;
    }
    @include max($sm) {
      font-size: 18px;
    }
  }
  .member-info {
  }
}
