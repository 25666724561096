/***** Simple elements, atoms *****/

.icon-w {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .icon {
    fill: currentColor;
    max-width: 100%;
    max-height: 100%;
  }
}

.heading {
  position: relative;
  margin: 0;
  padding-bottom: 10px;
  @include max($lg) {
    padding-bottom: 8px;
  }
  @include max($sm) {
    padding-bottom: 5px;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 5px;
    width: 200px;
    background: linear-gradient(to right, $yellow 0%, $brown 100%);
    @include max($lg) {
      width: 180px;
    }
    @include max($sm) {
      width: 150px;
      height: 4px;
    }
  }
}

.text {
  p {
    margin: 0 0 1.5em;
  }
  a {
    color: $brown;
    text-decoration: underline;
  }
  hr {
    border: none;
    width: 496px;
    height: 28px;
    background: url(../images/el/rhombs.png) 50% 50% no-repeat;
    background-size: contain;
    margin: 30px auto;
    @include max($lg) {
      width: 320px;
      height: 20px;
    }
  }
  ul {
    margin: 2em 0;
    padding: 35px 30px;
    background: $blue5;
    color: #fff;
    @include max($lg) {
      padding: 20px;
    }
    @include max($sm) {
      padding: 20px 15px;
    }
    li {
      list-style: none;
      padding: 0 0 0 40px;
      background: url(../images/icons/rhomb-brown.svg) 0 0 no-repeat;
      background-size: 25px 25px;
      @include max($sm) {
        background-size: 16px 16px;
        background-position: 0 3px;
        padding: 0 0 0 30px;
      }
      &:not(:last-child) {
        margin: 0 0 12px;
      }
    }
  }
  table {
    position: relative;
    margin: 2em 0;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: -7px;
      left: 0;
      height: 7px;
      width: 100%;
      background: linear-gradient(to right, $yellow 0%, $brown 100%);
      @include max($lg) {
        top: -7px;
        height: 4px;
      }
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: -7px;
      left: 0;
      height: 7px;
      width: 100%;
      background: linear-gradient(to right, $yellow 0%, $brown 100%);
      @include max($lg) {
        bottom: -7px;
        height: 4px;
      }
    }
    thead {
      position: relative;
      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0px;
        left: 0;
        height: 7px;
        width: 100%;
        background: linear-gradient(to right, $yellow 0%, $brown 100%);
        @include max($lg) {
          height: 4px;
        }
      }
    }
    tr {
    }
    th {
      font-size: 22px;
      line-height: 1.1;
      color: $blue;
      font-weight: bold;
      padding: 20px 25px 27px 0;
      text-align: left;
      vertical-align: top;
      @include max($xxl) {
        font-size: 18px;
        padding: 10px 20px 14px 0;
      }
      @include max($md) {
        font-size: 16px;
      }
      @include max($sm) {
        font-size: 14px;
      }
      @include max($xxs) {
        font-size: 13px;
        padding: 10px 10px 14px 0;
      }
      @include max($xxxs) {
        font-size: 12px;
      }
      &:last-child {
        padding-right: 0;
      }
    }
    td {
      color: #4d4d4d;
      font-size: 20px;
      padding: 20px 25px 20px 0;
      vertical-align: top;
      line-height: 1.2;
      @include max($xxl) {
        font-size: 18px;
        padding: 10px 20px 10px 0;
      }
      @include max($md) {
        font-size: 16px;
      }
      @include max($sm) {
        font-size: 14px;
      }
      @include max($xxs) {
        font-size: 13px;
        padding: 10px 10px 14px 0;
      }
      @include max($xxxs) {
        font-size: 12px;
      }
      &:last-child {
        padding-right: 0;
      }
    }
    tbody {
      tr {
        &:not(:last-child) {
          border-bottom: 2px solid $blue5;
        }
      }
    }
  }
  h2 {
    font-size: 24px;
    color: $blue;
    margin: 1.5em 0 1.2em;
    @include max($lg) {
      font-size: 20px;
    }
    @include max($sm) {
      font-size: 18px;
    }
  }
}
