/***** Homepage *****/

.section-intro {
  background: $blue5;
  padding: 138px 0 165px;
  margin-top: -80px;
  position: relative;
  overflow: hidden;
  @include max($lg) {
    margin-top: 0;
    padding: 30px 0 80px;
  }
  .intro-blocks {
    display: flex;
    @include max($lg) {
      display: block;
    }
  }
  .intro-block-lg {
    width: 820px;
    flex: 0 0 820px;
    @include max($xxl) {
      width: 66%;
      flex: 0 0 66%;
    }
    @include max($lg) {
      width: 100%;
      margin-bottom: 30px;
    }
    @include max($sm) {
      margin-bottom: 20px;
    }
    .intro-img {
      position: relative;
      margin-bottom: 20px;
      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: -7px;
        left: 0;
        height: 7px;
        width: 76%;
        background: linear-gradient(to right, $yellow 0%, $brown 100%);
      }
      a {
        display: block;
        line-height: 0;
        &:hover {
          opacity: 0.95;
        }
      }
      img {
        width: 100%;
      }
    }
    .intro-title {
      a {
        color: #fff;
        display: flex;
        display: flex;
        align-items: flex-start;
      }
      .text {
        font-size: 31px;
        font-weight: bold;
        line-height: 1.2;
        @include max($sm) {
          font-size: 24px;
        }
      }
      .intro-title-icon {
        width: 27px;
        flex: 0 0 27px;
        height: 27px;
        margin: 5px 25px 0 50px;
        @include max($lg) {
          margin: 1px 0 0 10px;
        }
        .icon {
        }
      }
    }
  }
  .intro-blocks-sm {
    flex: 1;
    margin-left: 20px;
    position: relative;
    z-index: 1;
    @include max($lg) {
      margin: 0;
      display: flex;
    }
    @include max($sm) {
      display: block;
    }
  }
  .intro-blocks-sm-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 0;
    @include max($lg) {
      display: none;
    }
    .intro-block-sm-bg {
      height: 255px;
      // background: rgba(1, 93, 187, 0.35);
      background: $blue;
      position: relative;
      &.intro-block-sm-bg-1 {
        margin-bottom: 27px;
      }
      &.intro-block-sm-bg-2 {
        background: $blue url(../images/el/school.png) 5px 14px no-repeat;
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: -7px;
        left: 0;
        height: 7px;
        width: 68%;
        background: linear-gradient(to right, $yellow 0%, $brown 100%);
      }
    }
  }
  .intro-block-sm {
    position: relative;
    z-index: 2;
    height: 255px;
    display: flex;
    align-items: stretch;
    line-height: 1.2;
    @include max($lg) {
      background: rgba($blue, 0.5);
      width: 48%;
      flex: 0 0 48%;
    }
    @include max($lg) {
      width: 100%;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: -7px;
      left: 0;
      height: 7px;
      width: 68%;
      background: linear-gradient(to right, $yellow 0%, $brown 100%);
      @include min($lg) {
        display: none;
      }
    }
    a {
      color: #fff;
      display: block;
      padding: 30px 30px 30px 40px;
      width: 100%;
      @include max($lg) {
        padding: 20px;
      }
      @include max($sm) {
        padding: 20px 15px;
      }
    }
    .intro-title {
      font-size: 31px;
      font-weight: bold;
      margin-bottom: 24px;
      display: block;
      @include max($sm) {
        font-size: 24px;
      }
    }
    .intro-subtitle {
      font-size: 20px;
      display: block;
      .line {
        display: block;
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
    &.intro-block-sm-1 {
      margin-bottom: 27px;
      @include max($lg) {
        margin: 0 2% 0 0;
      }
      @include max($sm) {
        margin: 0 0 20px;
      }
      .intro-subtitle {
        max-width: 250px;
      }
    }
    &.intro-block-sm-2 {
      text-align: right;

      @include max($lg) {
        background: rgba($blue, 0.5);
        margin: 0 0 0 2%;
      }
      @include max($sm) {
        margin: 0;
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(../images/el/school.png) 5px 14px no-repeat;
        opacity: 0.7;
        z-index: 1;
        @include min($lg) {
          display: none;
        }
      }
      a {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: relative;
        z-index: 2;
      }
      .intro-title {
        max-width: 250px;
      }
      .intro-subtitle {
        max-width: 250px;
      }
    }
  }
  .intro-blocks-shape {
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 0;
    transform: translateX(50%);
    width: 253px;
    height: 389px;
    background: url(../images/el/shape-v.svg) 50% 50% no-repeat;
    background-size: contain;
    @include max($lg) {
      display: none;
    }
  }
  .rhombs {
    position: absolute;
    bottom: 84px;
    left: 50%;
    transform: translateX(-50%);
    @include max($lg) {
      bottom: 28px;
    }
  }
}

.rhombs {
  width: 496px;
  height: 28px;
  background: url(../images/el/rhombs.png) 50% 50% no-repeat;
  background-size: contain;
  @include max($lg) {
    width: 320px;
    height: 20px;
  }
}

.section-news {
  position: relative;

  &:before {
    content: "";
    border-width: 40px 55px 0;
    border-style: solid;
    border-color: $blue5 transparent transparent;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    @include max($lg) {
      border-width: 20px 27px 0;
    }
  }
  .news-i {
    background: #fff;
    margin: -50px -100px 0;
    padding: 60px 100px 75px;
    @include max($lg) {
      margin: 0;
      padding: 30px 0 0;
    }
  }
  .news-h {
    display: flex;
    align-items: center;
    margin-bottom: 46px;
    @include max($lg) {
      margin-bottom: 20px;
    }
    @include max($sm) {
      display: block;
    }
    h2 {
      color: $blue;
      @include max($sm) {
        margin-bottom: 10px;
      }
    }
    .all {
      margin: 3px 0 0 auto;
      @include max($sm) {
        margin: 0;
      }
      a {
        display: flex;
        align-items: center;
        color: $blue;
      }
      .text {
        margin-right: 15px;
      }
      .icon-w {
        width: 25px;
        flex: 0 0 25px;
        .icon {
        }
      }
    }
  }
  .news-list {
    display: flex;
    margin: 0 -10px;
    @include max($lg) {
      display: block;
      margin: 0;
    }
    .news-item {
      padding: 0 10px;
      width: 33.3333%;
      flex: 0 0 auto;
      @include max($lg) {
        width: 100%;
        padding: 0 0 40px;
      }
      .item-img {
        margin-bottom: 25px;
        position: relative;
        @include max($lg) {
          margin-bottom: 20px;
          display: inline-block;
        }
        @include max($sm) {
          margin-bottom: 15px;
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          bottom: -7px;
          height: 7px;
          width: 68%;
          background: linear-gradient(to right, $yellow 0%, $brown 100%);
        }
        a {
          display: block;
          line-height: 0;
          &:hover {
            opacity: 0.9;
          }
          img {
          }
        }
      }
      .item-body {
        .item-title {
          margin-bottom: 10px;
          a {
            font-weight: bold;
            font-size: 24px;
            line-height: 1.2;
            @include max($lg) {
              font-size: 22px;
            }
            @include max($sm) {
              font-size: 20px;
            }
          }
        }
        .item-summary {
          margin-bottom: 15px;
          line-height: 1.2;
          @include max($sm) {
            margin-bottom: 10px;
          }
        }
        .more {
          display: flex;
          justify-content: flex-end;
          a {
            display: flex;
            align-items: center;
            color: $brown;
          }
          .text {
            margin-right: 15px;
          }
          .icon-w {
            width: 25px;
            flex: 0 0 25px;
            .icon {
            }
          }
        }
      }
    }
  }
}

.section-games {
  background: $blue5;
  position: relative;
  padding: 55px 0 0;
  @include max($lg) {
    padding: 30px 0 0;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 163px;
    background: #02284f;
  }
  .container {
    position: relative;
    z-index: 1;
  }
  h2 {
    color: #fff;
    margin-bottom: 32px;
    @include max($lg) {
      margin-bottom: 20px;
    }
  }
  .game-list-swiper {
  }
  .swiper-wrapper {
  }
  .game-item {}
  .swiper-prev,
  .swiper-next {
    width: 32px;
    height: 52px;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    transition: all 0.2s;
    @include max($lg) {
      width: 27px;
      height: 42px;
    }
    &:hover {
      opacity: 0.8;
    }
    &.swiper-button-disabled {
      opacity: 0.4;
    }
    .icon {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .swiper-prev {
    left: 70px;
    @include max($xxxl) {
      left: 20px;
    }
  }
  .swiper-next {
    right: 70px;
    @include max($xxxl) {
      right: 20px;
    }
  }

  .games-b {
    padding: 52px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    @include max($lg) {
      padding: 40px 0;
    }
    .link {
      flex-grow: 1;
      flex-basis: 0;
      @include max($sm) {
        width: 100%;
        flex: 0 0 100%;
      }
      a {
        color: #fff;
        display: flex;
        align-items: center;
      }
      .icon-w {
        .icon {
          @include max($sm) {
            order: 1;
          }
        }
      }
      .text {
        @include max($sm) {
          order: 2;
        }
      }
      &.link-left {
        @include max($lg) {
          order: 2;
        }
        @include max($sm) {
          margin-bottom: 10px;
        }
        .icon-w {
          margin-right: 15px;
        }
      }
      &.link-right {
        display: flex;
        justify-content: flex-end;
        text-align: right;
        @include max($lg) {
          order: 3;
        }
        @include max($sm) {
          text-align: left;
          justify-content: flex-start;
        }
        .icon-w {
          margin-left: 15px;
          @include max($sm) {
            margin: 0 15px 0 0;
          }
        }
      }
    }
    .rhombs {
      @include max($lg) {
        order: 1;
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
}

.game-item {
  color: #fff;
  text-align: center;
  position: relative;
  padding: 0 10px 30px;

  .game-t {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 20px;
    position: relative;
    padding-top: 55px;
    z-index: 2;

    &:after {
      content: "";
      display: block;
      position: absolute;
      z-index: 0;
      top: 12px;
      left: 50%;
      transform: translateX(-50%);
      width: 229px;
      height: 198px;
      background: url(../images/el/game-t-bg.svg) 50% 50% no-repeat;
      background-size: contain;
    }

    .game-team {
      position: relative;
      z-index: 1;
      width: 45%;
      flex: 0 0 45%;

      @include max($lg) {
        width: 40%;
        flex: 0 0 40%;
      }

      .game-team-logo {
        width: 130px;
        max-width: 100%;
        height: 140px;
        margin: 0 auto 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .game-team-title {
        line-height: 1.2;

        @include max($sm) {
          font-size: 14px;
        }

        .title {
          display: block;
        }

        .subtitle {
          display: block;
        }
      }
    }

    .game-score {
      font-size: 36px;
      line-height: 1.2;
      font-weight: bold;
      position: relative;
      z-index: 1;
      padding: 0 10px;
      margin-top: 47px;

      @include max($lg) {
        font-size: 26px;
        margin-top: 55px;
      }

      @include max($sm) {}
    }

    .game-vs {
      font-size: 26px;
      font-weight: bold;
      position: relative;
      z-index: 1;
      padding: 0 10px;
      margin-top: 50px;
    }
  }

  .game-b {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.2;
    z-index: 2;

    @include max($sm) {
      font-size: 20px;
    }

    .game-date {}

    .game-location {
      color: $brown;
    }
  }

  &.game-item-past {
    &:before {
      content: "";
      border-width: 40px 55px 0;
      border-style: solid;
      border-color: $blue5 transparent transparent;
      position: absolute;
      top: 22px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 22px;
      height: 152px;
      width: 100%;
      background: $blue;
      z-index: 0;
    }
  }

  &.game-item-coming {
    background: $blue;

    &:before {
      content: "";
      border-width: 40px 55px 0;
      border-style: solid;
      border-color: $blue5 transparent transparent;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      bottom: 0px;
      height: 7px;
      width: 100%;
      background: linear-gradient(to right, $yellow 0%, $brown 100%);
      z-index: 1;
    }

    .game-t {
      margin-bottom: 40px;
    }
  }
}

.section-sponsors {
  padding: 72px 0 90px;
  @include max($lg) {
    padding: 30px 0;
  }
  h2 {
    margin-bottom: 55px;
    color: $blue;
    @include max($lg) {
      margin-bottom: 30px;
    }
  }

  .sponsor-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include max($lg) {
      justify-content: center;
      flex-wrap: wrap;
    }
  }
  .sponsor-item {
    padding: 0 10px;
    @include max($lg) {
      padding: 5px 20px;
    }
    img {
      min-width: 123px;
      min-height: 56px;
      @include max($lg) {
        min-width: 80px;
        min-height: 40px;
      }
    }
  }
  .sponsors-b {
    padding: 52px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    @include max($lg) {
      padding: 40px 0 30px;
    }
    .link {
      flex-grow: 1;
      flex-basis: 0;
      @include max($sm) {
        width: 100%;
        flex: 0 0 100%;
      }
      a {
        color: $blue5;
        display: flex;
        align-items: center;
      }
      .icon-w {
        color: $brown;
        .icon {
          @include max($sm) {
            order: 1;
          }
        }
      }
      .text {
        @include max($sm) {
          order: 2;
        }
      }
      &.link-left {
        @include max($lg) {
          order: 2;
        }
        @include max($sm) {
          margin-bottom: 10px;
        }
        .icon-w {
          margin-right: 15px;
        }
      }
      &.link-right {
        display: flex;
        justify-content: flex-end;
        text-align: right;
        @include max($lg) {
          order: 3;
        }
        @include max($sm) {
          text-align: left;
          justify-content: flex-start;
        }
        .icon-w {
          margin-left: 15px;
          @include max($sm) {
            margin: 0 15px 0 0;
          }
        }
      }
    }
    .rhombs {
      @include max($lg) {
        order: 1;
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
}
