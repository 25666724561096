/***** Text *****/

.section-article {
  .article-top {
    background: $blue5;
    padding: 138px 0 50px;
    margin: -80px 0 40px;
    position: relative;
    color: #fff;
    @include max($xxl) {
      margin-top: 0;
      padding: 30px 0 40px;
    }
    @include max($lg) {
      margin-top: 0;
      padding: 30px 0 30px;
    }
    .article-top-i {
      display: flex;
      position: relative;
      @include max($lg) {
        display: block;
      }
    }
    .article-top-content {
      flex: 1;
      margin-right: 20px;
      position: relative;
      z-index: 1;
      @include max($lg) {
        margin: 0 0 20px;
      }
      .article-top-img {
        margin-bottom: 32px;
        line-height: 0;
        position: relative;
        &:after {
          content: "";
          display: block;
          position: absolute;
          bottom: -7px;
          left: 0;
          height: 7px;
          width: 76%;
          background: linear-gradient(to right, $yellow 0%, $brown 100%);
        }
        img {
          width: 100%;
        }
      }
      .article-h {
        h1 {
          margin: 0;
          color: #fff;
          display: inline;
        }
        .icon-w {
          display: inline-block;
          vertical-align: middle;
          margin-left: 6px;
          .icon {
          }
        }
      }
    }
    .article-top-sidebar {
      width: 400px;
      flex: 0 0 400px;
      position: relative;
      z-index: 1;
      font-size: 20px;
      @include max($xxl) {
        width: 320px;
        flex: 0 0 320px;
        // font-size: 16px;
      }
      @include max($lg) {
        width: 100%;
        max-width: 300px;
      }
    }
    .article-top-menu {
      display: flex;
      @include max($xxl) {
        display: block;
      }
      ul {
        padding: 0;
        margin: 0;
        width: 283px;
        flex: 0 0 283px;
        margin-left: auto;
        @include max($xxl) {
          width: 100%;
        }
        li {
          list-style: none;
          margin: 0;
          padding: 0;
          font-weight: bold;
          text-transform: uppercase;
          a {
            position: relative;
            display: block;
            line-height: 20px;
            padding: 10px 45px;
            color: #fff;
            @include max($lg) {
              padding: 7px 10px;
            }
          }
          span {
            position: relative;
            display: block;
            line-height: 20px;
            padding: 10px 45px;
            color: $yellow;
            background: #02284f;
            @include max($lg) {
              padding: 7px 10px;
            }
            &:after {
              content: "";
              display: block;
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0;
              height: 4px;
              background: linear-gradient(to right, $yellow 0%, $brown 100%);
              z-index: 1;
            }
          }
        }
      }
    }
    .article-top-shape {
      position: absolute;
      z-index: 0;
      top: 0;
      right: 273px;
      width: 253px;
      height: 389px;
      background: url(../images/el/shape-v.svg) 50% 50% no-repeat;
      background-size: contain;
      @include max($xxl) {
        right: 20%;
      }
      @include max($lg) {
        // display: none;
      }
    }
  }
  .article-bottom {
    padding-bottom: 40px;
    @include max($sm) {
      padding-bottom: 30px;
    }
    .article-bottom-i {
      display: flex;
      @include max($lg) {
        display: block;
      }
    }
    .article-content {
      flex: 1;
      margin-right: 20px;
      padding-bottom: 20px;
      @include max($lg) {
        margin: 0;
      }

      .article-text {
      }
      .social-widget {
        margin: 50px 35px;
        @include max($lg) {
          margin: 30px 0;
        }
      }
    }

    .article-sidebar {
      width: 400px;
      flex: 0 0 400px;
      @include max($xxl) {
        width: 320px;
        flex: 0 0 320px;
      }
      @include max($lg) {
        width: 100%;
        max-width: 400px;
      }
      .side-block {
        background: $blue;
        position: relative;
        padding: 32px 30px 30px 40px;
        min-height: 255px;
        color: #fff;
        @include max($lg) {
          padding: 20px;
          min-height: 0;
        }
        @include max($sm) {
          padding: 20px 15px;
        }
        &:not(:last-child) {
          margin-bottom: 28px;
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          bottom: -4px;
          left: 0;
          height: 7px;
          width: 70%;
          background: linear-gradient(to right, $yellow 0%, $brown 100%);
        }
        h3,
        .h3 {
          margin: 0 0 20px;
        }
        .side-block-body {
          .side-menu {
            ul {
              margin: 0;
              padding: 0;
              li {
                list-style: none;
                padding: 0 0 0 40px;
                background: url(../images/icons/rhomb-brown.svg) 0 0 no-repeat;
                background-size: 25px 25px;
                @include max($sm) {
                  background-size: 16px 16px;
                  background-position: 0 3px;
                  padding: 0 0 0 30px;
                }
                &:not(:last-child) {
                  margin: 0 0 12px;
                }
                a {
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
}

.section-gallery {
  background: $blue5;
  padding: 65px 0 55px;
  position: relative;
  color: #fff;
  @include max($lg) {
    padding: 40px 0;
  }
  @include max($sm) {
    padding: 30px 0;
  }
  &:after {
    content: "";
    border-width: 40px 55px 0;
    border-style: solid;
    border-color: $blue5 transparent transparent;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    @include max($lg) {
      border-width: 20px 27px 0;
    }
  }
  h2 {
    margin-bottom: 40px;
    @include max($lg) {
      margin-bottom: 20px;
    }
  }

  .gallery-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    @include max($sm) {
      margin: 0 -5px;
    }
    .photo {
      width: 16.6666%;
      flex: 0 0 16.6666%;
      padding: 0 10px 20px;
      display: block;
      line-height: 0;
      @include max($xxl) {
        width: 20%;
        flex: 0 0 20%;
      }
      @include max($lg) {
        width: 25%;
        flex: 0 0 25%;
      }
      @include max($md) {
        width: 33.3333%;
        flex: 0 0 33.3333%;
      }

      @include max($sm) {
        width: 50%;
        flex: 0 0 50%;
        padding: 0 5px 10px;
      }
      a {
        display: block;
        line-height: 0;
        &:hover {
          opacity: 0.9;
        }
      }
      img {
        width: 100%;
      }
    }
  }
}
