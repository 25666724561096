/***** Footer *****/

.footer {
  position: relative;
  background: $blue5;
  @include max($xxxl) {
    font-size: 18px;
  }
  .footer-logo {
    position: absolute;
    z-index: 2;
    top: -43px;
    left: 50%;
    transform: translateX(-50%);
    width: 111px;
    height: 158px;
    @include max($lg) {
      width: 60px;
      height: auto;
      top: -35px;
    }
    a {
      display: block;
      line-height: 0;
      &:hover {
        opacity: 1;
      }
    }
    img {
    }
  }
  .footer-i {
    position: relative;
    padding: 120px 0 110px;
    display: flex;
    align-items: flex-end;
    color: #fff;
    @include max($lg) {
      display: block;
      padding: 60px 0 30px;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: -7px;
      left: -100px;
      right: -100px;
      height: 7px;
      border-width: 1px 0;
      border-color: $blue;
      border-style: solid;
      background: $yellow;
      @include max($lg) {
        left: 0;
        right: 0;
      }
    }
  }
  .footer-l {
    width: 420px;
    flex: 0 0 420px;
    padding-bottom: 6px;
    @include max($xxxl) {
      width: 350px;
      flex: 0 0 350px;
    }
    @include max($lg) {
      padding: 0 0 30px;
    }
    p {
      &:last-child {
        margin-bottom: 0;
      }
      a {
        color: $yellow;
      }
    }
  }
  .footer-r {
    flex: 1;
    .footer-menu {
      margin-bottom: 65px;
      @include max($lg) {
        margin-bottom: 30px;
      }
      ul {
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;
        flex-wrap: wrap;
        @include max($sm) {
          display: block;
        }
        li {
          list-style: none;
          display: block;
          margin: 0 30px 5px 0;
          text-transform: uppercase;
          font-weight: bold;
          &:last-child {
            margin-right: 0;
          }
          a {
            color: #fff;
          }
          > span {
          }
        }
      }
    }

    .footer-b {
      display: flex;
      align-items: center;
      padding-left: 100px;
      @include max($lg) {
        padding-left: 0;
      }
      @include max($sm) {
        display: block;
      }
    }
    .footer-social {
      display: flex;
      @include max($sm) {
        margin-bottom: 20px;
      }
      .icon-w {
        &:not(:last-child) {
          margin-right: 40px;
        }
        .icon {
        }
      }
    }
    .footer-copy {
      margin-left: auto;
    }
  }
}
