/***** Header *****/

.header {
  position: relative;
  z-index: 5;
  &:after {
    @include max($lg) {
      content: "";
      display: block;
      position: absolute;
      bottom: -5px;
      left: 0;
      height: 5px;
      width: 100%;
      background: linear-gradient(to right, $yellow 0%, $brown 100%);
    }
  }
  .header-i {
    position: relative;
    @include max($lg) {
      height: 62px;
    }
  }
  .header-brand {
    position: absolute;
    left: 0;
    top: 16px;
    color: $blue;
    font-weight: bold;
    text-transform: uppercase;
    @include max($lg) {
      left: 70px;
      right: 100px;
      top: 50%;
      transform: translateY(-50%);
    }
    @include max($sm) {
      width: 140px;
    }
    a {
      color: $blue;
      &:hover {
      }
    }
  }
  .header-logo {
    position: absolute;
    z-index: 2;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: 111px;
    height: 158px;
    @include max($lg) {
      top: 3px;
      left: 0;
      transform: none;
      width: 60px;
      height: auto;
    }
    a {
      display: block;
      line-height: 0;
      &:hover {
        opacity: 1;
      }
    }
    img {
    }
  }
  .header-w {
    @include max($lg) {
      opacity: 0;
      visibility: hidden;
      transition: all 0.2s;
      position: fixed;
      overflow-y: auto;
      top: 62px;
      bottom: 0;
      left: 0;
      right: 0;
      background: #fff;
    }
    &.active {
      @include max($lg) {
        opacity: 1;
        visibility: inherit;
      }
    }
  }
  .header-t {
    .header-t-i {
      height: 67px;
      padding: 5px 0 12px;
      display: flex;
      align-items: center;
      position: relative;
      @include max($lg) {
        height: auto;
        padding: 40px 20px 20px;
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: -100px;
        right: -100px;
        height: 7px;
        border-width: 1px 0;
        border-color: $blue;
        border-style: solid;
        background: $yellow;
        @include max($lg) {
          display: none;
        }
      }
    }

    .header-t-r {
      margin-left: auto;
      display: flex;
      align-items: center;
      @include max($lg) {
        display: block;
        margin: 0;
      }
    }
    .header-search {
      display: flex;
      align-items: center;
      margin-right: 93px;
      @include max($lg) {
        margin: 0 0 20px;
        width: 100%;
      }
      .icon-w {
        color: $blue;
        margin-right: 15px;
      }
      input {
        border: none;
        font-weight: bold;
        color: $blue;
        transition: all 0.3s;
        padding: 0;
        text-transform: uppercase;
        border-bottom: 1px solid rgba($blue, 0);
        width: 80px;
        @include max($lg) {
          width: 100%;
          max-width: 200px;
        }
        @include placeholder {
          opacity: 1;
          transition: all 0.2s;
        }
        &:focus {
          width: 200px;
          border-block-color: $blue;
          @include placeholder {
            opacity: 0.3;
          }
        }
      }
    }
    .header-lang {
      display: flex;
      align-items: center;
      a {
        color: $yellow;
        font-weight: bold;
        text-transform: uppercase;
        &:not(:last-child) {
          margin-right: 25px;
        }
        &:hover {
        }
      }
      span {
      }
    }
  }
  .header-b {
    .header-menu {
      position: relative;
      height: 80px;
      display: flex;
      align-items: center;
      margin: 0 -100px;
      @include max($lg) {
        display: block;
        height: auto;
        padding: 0 20px 20px;
        margin: 0;
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 7px;
        border-width: 1px 0;
        border-color: $blue;
        border-style: solid;
        background: $yellow;
        @include max($lg) {
          display: none;
        }
      }
      > ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        margin: 0;
        width: 50%;
        flex: 0 0 50%;
        @include max($lg) {
          display: block;
          width: 100%;
        }
        > li {
          list-style: none;
          font-weight: bold;
          text-transform: uppercase;

          color: $blue;
          @include max($lg) {
            // margin-bottom: 10px;
          }
          > a,
          > span {
            padding: 25px 0;
            display: block;
            line-height: 30px;
            position: relative;
            z-index: 2;
            @include max($lg) {
              padding: 5px 0;
            }
          }
          > a {
            &:hover {
              opacity: 1;
              color: $yellow;
            }
          }
          > span {
          }
        }
        &.menu-left {
          padding: 0 11% 0 100px;
          @include max($lg) {
            padding: 0;
          }
          > li {
            &:nth-child(2) {
              > ul {
              }
            }
          }
        }
        &.menu-right {
          padding: 0 100px 0 11%;
          @include max($lg) {
            padding: 0;
          }
          > li {
          }
        }
        li.parent-item {
          @include max($lg) {
            margin-bottom: 20px;
          }
          > a {
          }
          ul {
            opacity: 0;
            visibility: hidden;
            transition: all 0.3s;
            position: absolute;
            top: 73px;
            left: 0;
            right: 0;
            background: $blue5;
            padding: 50px 0 120px;
            z-index: 1;
            @include max($lg) {
              position: relative;
              opacity: 1;
              visibility: inherit;
              top: auto;
              background: none;
              padding: 0 0 0 20px !important;
              font-size: 16px;
            }
            @include max($sm) {
              font-size: 14px;
            }
            &:after {
              content: "";
              display: block;
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              height: 7px;
              border-width: 1px 0;
              border-color: $blue;
              border-style: solid;
              background: $yellow;
              @include max($lg) {
                display: none;
              }
            }
            li {
              display: block;
              list-style: none;
              margin: 0;
              padding: 0;
              a {
                position: relative;
                display: inline-block;
                line-height: 20px;
                padding: 5px 0;
                width: 190px;
                @include max($lg) {
                  width: auto;
                  display: block;
                }
                span {
                  position: relative;
                  z-index: 2;
                }
                &:hover {
                  opacity: 1;
                  color: $yellow;
                  &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    left: -45px;
                    right: -45px;
                    top: 0;
                    bottom: 0;
                    background: #02284f;
                    z-index: 0;
                    @include max($lg) {
                      display: none;
                    }
                  }
                  &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    left: -45px;
                    right: -45px;
                    bottom: 0;
                    height: 4px;
                    border-width: 1px 0;
                    border-color: $blue;
                    border-style: solid;
                    background: $yellow;
                    z-index: 1;
                    @include max($lg) {
                      display: none;
                    }
                  }
                }
              }
            }
          }
          &:hover {
            > a,
            > span {
              color: $yellow;
              opacity: 1;
              &:after {
                content: "";
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 7px;
                border-width: 1px 0;
                border-color: $blue;
                border-style: solid;
                background: $yellow;
                @include max($lg) {
                  display: none;
                }
              }
            }
            ul {
              opacity: 1;
              visibility: inherit;
            }
          }
        }
      }
    }
  }
  .header-toggler {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 10px 0;
    cursor: pointer;
    transition: all 0.2s;
    @include min($lg) {
      display: none;
    }
    @include max($sm) {
      width: 45px;
    }
    &:hover {
      opacity: 0.8;
    }
  }
}

body.page-home,
body.page-header-light {
  .header {
    .header-b {
      .header-menu {
        @include min($lg) {
          background: $blue5;
        }
        ul {
          li {
            a {
              @include min($lg) {
                color: #fff;
              }

              &:hover {
                @include min($lg) {
                  color: $yellow;
                }
              }
            }
            > span {
            }
          }
        }
      }
    }
  }
}

body.menu-isactive {
  overflow: hidden;
}
