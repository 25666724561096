/***** Default and helpers *****/

@import "mixins";
@import "variables";

@font-face {
  font-family: "Rubik";
  src: local("Rubik Regular"), local("Rubik-Regular"),
    url("../fonts/Rubik-Regular.woff2") format("woff2"),
    url("../fonts/Rubik-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Rubik";
  src: local("Rubik Medium"), local("Rubik-Medium"),
    url("../fonts/Rubik-Medium.woff2") format("woff2"),
    url("../fonts/Rubik-Medium.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

html {
  padding: 0;
  margin: 0;
  height: 100%;
}
body {
  overflow-x: hidden;
  color: $black;
  font-family: $font-basic;
  font-size: 20px;
  height: 100%;
  line-height: 1.4;
  @include max($sm) {
    font-size: 16px;
  }
}

* {
  box-sizing: border-box;
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

input[type="submit"],
input[type="text"],
input[type="number"],
input[type="tel"],
input[type="email"],
input[type="password"],
input[type="url"],
textarea {
  -webkit-appearance: none;
  appearance: none;
}

input,
textarea,
label {
  font-family: $font-basic;
}
a,
button,
input,
textarea,
select {
  outline: none;
}

input,
textarea {
  @include placeholder {
    opacity: 0.7;
  }
  &:focus {
    @include placeholder {
      opacity: 0.4;
    }
  }
}

textarea {
  resize: vertical;
}

table {
  border-collapse: collapse;
}

a {
  color: $blue;
  text-decoration: none;
  transition: all 0.2s;
  &:hover {
    opacity: 0.8;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  font-family: $font-heading;
  line-height: 1.2;
  font-weight: bold;
}

h1,
.h1 {
  font-size: 44px;
  @include max($lg) {
    font-size: 36px;
  }
  @include max($sm) {
    font-size: 30px;
  }
}
h2,
.h2 {
  font-size: 36px;
  @include max($lg) {
    font-size: 30px;
  }
  @include max($sm) {
    font-size: 24px;
  }
}
h3,
.h3 {
  font-size: 30px;
  @include max($lg) {
    font-size: 26px;
  }
  @include max($sm) {
    font-size: 22px;
  }
}
h4 {
  font-size: 24px;
  @include max($lg) {
    font-size: 22px;
  }
  @include max($sm) {
    font-size: 20px;
  }
}

p {
  margin: 0 0 1em;
}

b,
strong {
}

img {
  max-width: 100%;
  height: auto;
}
