/* Color */
$blue: #003f7d;
$blue5: #022d5a;
$brown: #ac7949;
$yellow: #deb353;
$black: #4d4d4d;

$font-heading: "Rubik", sans-serif;
$font-basic: "Rubik", sans-serif;

/* Size */
$xxxs: 360px;
$xxs: 400px;
$xs: 480px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1280px;
$xxxl: 1400px;
$huge: 1600px;
$xhuge: 1800px;
$xxhuge: 2000px;
